<template>
  <div
    class="sourceItem mediaSourceList"
    :class="['sourceItem mediaSourceList',showSizeClass=='m'?'sourceItemM':'',showSizeClass=='l'?'sourceItemL':'']"
    :id="sourceId"
    :rId="receiverId"
    :share-data="currentSocketData.shareInfo">
    <!--media框-->
    <span
      class="iconfont i-closeInput closeMediaIcon"
      @click="toggleShareBox()"
      v-show="shareFlag && selectRIsLive"></span>
    <MediaRBox
      v-if="selectRIsLive && ifOpenMediaService && shareFlag"
      :shareFlag="selectRIsLive && shareFlag"
      :rId="receiverId"
      :sId="sourceId"
      :id="sourceId"
      :sType='allSourceDataUpdate[sourceListItemOps.peerId].type'
      :shareInfo="currentSocketData.shareInfo" />
    <!--使用此判断可以解决第一次渲染没有值 而导致报错的问题 现在再index.vue做处理-->
    <div
      :class="['thumbnail-view-img pr',showSizeClass=='m'?'imgMShow':'',showSizeClass=='l'?'imgLShow':'']"
      @click="showVideo(sourceListItemOps.peerId)"
      :title="sourceListItemOps.peerId">
      <volume-column
        v-if="allSourceDataUpdate[sourceListItemOps.peerId] && allSourceDataUpdate[sourceListItemOps.peerId].status == '2'"
        :rId='deviceDetailid||""'></volume-column>
      <device-detail
        :isOpen='isShow.deviceDetail'
        :rId='deviceDetailid||""'
        v-if="allSourceDataUpdate[sourceListItemOps.peerId] && allSourceDataUpdate[sourceListItemOps.peerId].status == '2'"></device-detail>

      <div
        class="detail_btn"
        v-if="allSourceDataUpdate[sourceListItemOps.peerId] && allSourceDataUpdate[sourceListItemOps.peerId].status == '2'">
        <device-detail-btn
          :isShowDeviceDetail='isShow.deviceDetail'
          :rId='deviceDetailid||""'
          @changeShowDeviceDetail='changeShowDeviceDetail'></device-detail-btn>
      </div>
      <img
        :src="thumImgUrl"
        class="sourceShowImg cursorImg"
        :onerror="logo"
        v-if="allSourceDataUpdate[sourceListItemOps.peerId].status == '0'"
        alt="">
      <img
        v-else
        :src="thumImgUrl"
        class="sourceShowImg"
        :onerror="logo"
        alt="">
    </div>
    <div class="sourceItemName">
      <i
        class="bookmark_icon pointer f_left"
        :class='["iconfont"]'
        @click='operationBookmarkBox(true)'>{{sourceListItemOps.userBookmarkDevice?'&#xe63b;':'&#xe636;'}}</i>
      <div
        class="left sourceInner"
        :title="allSourceDataUpdate[sourceListItemOps.peerId] && allSourceDataUpdate[sourceListItemOps.peerId].name ? allSourceDataUpdate[sourceListItemOps.peerId].name : ''">
        <span
          class="iconfont deviceIcon"
          v-html="setIcon(true)"
          :title="setIcon(false)"></span>
        {{allSourceDataUpdate[sourceListItemOps.peerId] && allSourceDataUpdate[sourceListItemOps.peerId].name ? allSourceDataUpdate[sourceListItemOps.peerId].name : ''}}
      </div>
      <!--media相关-->
      <div
        class="right shareToggleIcon"
        v-show="selectRIsLive && ifOpenMediaService && mediaLoaded">
        <span
          class="iconfont i-share"
          :title="$t('lang.i18n_moreShare')"
          @click="toggleShareBox()">
        </span>
        <span
          v-show="currentSocketData.shareSize && currentSocketData.shareSize != 0"
          class="sharingNum globalNum">{{currentSocketData.shareSize}}</span>
      </div>
      <div
        class="right descIcon"
        v-if="sourceListItemOps.showEventIcon && openOnGoingEvent">
        <i
          class="el-icon-date"
          :class="{'notPerimission': !sourceListItemOps.hasSlugPerimission, 'onGoingIcon': sourceListItemOps.isOnGoing}"
          @click="showSlugBox(sourceListItemOps)"></i>
      </div>
      <div
        class="right pointer voipBox"
        v-if="allSourceDataUpdate[sourceListItemOps.peerId]?allSourceDataUpdate[sourceListItemOps.peerId].type=='T'&&allSourceDataUpdate[sourceListItemOps.peerId].status != 0:false"
        @click="startVoip(allSourceDataUpdate[sourceListItemOps.peerId])">
        <img :src="allSourceDataUpdate[sourceListItemOps.peerId]|showVoipImg">
      </div>
      <div class="hide">
        {{sourceListItemOps.peerId}} {{gridRequestStatus[this.sourceListItemOps.peerId]}}
      </div>
      <img
        src="../../assets/img/accept.png"
        alt=""
        class="left pairedImg"
        v-if="gridRequestStatus[this.sourceListItemOps.peerId] && gridRequestStatus[this.sourceListItemOps.peerId] == 3">
      <img
        src="../../assets/img/pending.png"
        alt=""
        class="left pairedImg"
        v-if="gridRequestStatus[this.sourceListItemOps.peerId] && gridRequestStatus[this.sourceListItemOps.peerId] == 1">
    </div>
    <!--显示live Status-->
    <div
      class="t_thumbnail_view_item_icon liveBg"
      v-if="allSourceDataUpdate[sourceListItemOps.peerId] && allSourceDataUpdate[sourceListItemOps.peerId].status == '2'">
      {{$t('lang.home_Live')}}
    </div>
    <div class="switchSourcePeer">
      <div class="sourceSearch left">
        <!--引入下拉框组件-->
        <SelectSourceR
          :sourceItem="allSourceDataUpdate[sourceListItemOps.peerId]"
          :key="sourceListItemOps.peerId"
          @getSelectInfo="getSelectInfo"
          :sourceListItemOps="sourceListItemOps"
          :hadSelectDevice="isSelectNewR"
          type="T"
          @changeHadSelectDevice="changeHadSelectDevice"></SelectSourceR>
      </div>

      <!--引入Take组件-->
      <videoButton
        :style="{'margin-left': '10px','float': showSizeClass== 'm'||'l'?'right':'left','width':'73px'}"
        :sourceItem='allSourceDataUpdate[sourceListItemOps.peerId]'
        :hadSelectDevice="isSelectNewR"
        flag="T"
        :deviceList="deviceList"
        :key="sourceListItemOps.peerId"
        @changeHadSelectDevice="changeHadSelectDevice"
        @stopRState="stopRState">
        {{allSourceData[sourceListItemOps.peerId]}}
      </videoButton>
    </div>
    <audio
      ref="audioObject"
      autoplay></audio>
    <div class="hide">
      {{locationInfoCu}} {{allSourceDataUpdate[sourceListItemOps.peerId].status}}
    </div>
    <div
      class="locationSet"
      :title="locationInfoCu.address ? locationInfoCu.address :''"
      v-if="allSourceDataUpdate[sourceListItemOps.peerId].status != 0 && locationInfoCu.address && locationShow">
      <!--给设备添加位置显示-->
      <a
        @click="gotoMap(locationHref)"
        v-if="locationHref != '' && locationHref != 'NA'">
        <i class="iconfont">&#xe654;</i>
        {{locationInfoCu.address}}
      </a>
      <span v-if="locationHref == 'NA'">
        <i class="iconfont">&#xe654;</i>{{$t('lang.noAvailable')}}</span>
    </div>

    <!-- add bookmark -->
    <add-bookmark
      :visible='isShow'
      :lastBookmark='sourceListItemOps.userBookmarkDevice'
      :parentBookmark="sourceListItemOps.parentBookmarkDevice || {}"
      :deviceId='sourceListItemOps.peerId'
      :deviceObj='{id:sourceListItemOps.peerId,type:"MySource"}'
      @closeBox='operationBookmarkBox'>
    </add-bookmark>
    <GridDescription
      v-if="confirmObj.showFlag && openOnGoingEvent"
      :confirmObj="confirmObj"></GridDescription>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { VoipImage } from '@/config.js'

import videoButton from '@/components/VideoButton'
import SelectSourceR from '@/components/SelectSourceR'
import AddBookmark from '@/components/addBookmark'
import Bus from '@/assets/js/vueBus'
import GridDescription from '@/components/gridDescription'
import { mySourceAnalyze } from '@/assets/js/googleAnalyze/mySource.js'
import VolumeColumn from '@/components/VolumeColumn'
import DeviceDetail from '@/components/DeviceDetail'
import DeviceDetailBtn from '@/components/DeviceDetailBtn'
import MediaRBox from '@/components/mediaRbox'
import { handleSource } from '@/assets/js/shareCommon.js'
import { SHOWDEVICEIMG, SETSOURCEICON } from '@/assets/js/filters'

export default {
  props: {
    sourceListItemOps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    allSourceData: {
      type: Array,
      default: () => {
        return []
      }
    },
    listKey: {
      type: Number,
      default: 0
    },
    currentSocketData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    gridTokenStatus: {
      type: Object,
      default: () => {
        return {}
      }
    },
    locationInfo: {
      type: Array,
      default: () => {
        return []
      }
    },
    imgUrlTime: Object,
    locationShow: {
      default: true,
      type: Boolean
    }
  },
  name: 'sourceItem',
  components: { videoButton, SelectSourceR, VolumeColumn, DeviceDetail, DeviceDetailBtn, MediaRBox, AddBookmark, GridDescription },
  data () {
    return {
      scrollData: {
        onScroll: this.onScroll,
        distance: 10
      },
      isShow: {
        deviceDetail: true,
        bookmark: false
      },
      dialogVisible: false,
      value1: 0,
      rlistArr: [],
      everyRList: [],
      page: 1,
      size: 10,
      noMore: false,
      loading: false,
      hasNextPage: true, // 是否有下一页
      // selectData : SelectData,
      currentSourceId: this.sourceListItemOps.peerId,
      rlistFlag: false, // 当前的r列表是否显示.split(";")[0]
      rDisplayInfo: this.sourceListItemOps.livePeerName ? this.sourceListItemOps.livePeerName.split(';')[0] : '',
      logo: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"', // 如果图片报错使用默认图片,并解决图片闪烁问题
      isSelectNewR: false, // 是否重新选择了R,
      isOperate: this.sourceListItemOps.status == 2,
      rItemId: this.sourceListItemOps.livePeerId ? this.sourceListItemOps.livePeerId.split(';')[0] : '', // 被选择的R的id
      firstRid: '', // 如果没在source 没在live将r列表的第一个r的id传给播放组件
      placeholder: this.$t('lang.home_EnterReceiverNamePeerID'),
      isLoading: false,
      thisSourceType: '',
      currentSelectRStatus: '',
      deviceList: '', // 向Take按钮传送的值
      allSourceDataUpdate: this.allSourceData,
      currentClick: '', // 当前点击的时间
      lastClick: '', // 上次点击的时间
      deviceDetailid: this.sourceListItemOps.livePeerId,
      // media相关
      shareFlag: false,
      receiverId: this.sourceListItemOps.livePeerId ? this.sourceListItemOps.livePeerId.split(';')[0] : '',
      sourceId: this.currentSocketData.peerId,
      selectRIsLive: this.currentSocketData && this.currentSocketData.status == '2', // 选中的R是否正在和当前source进行live
      ifOpenMediaService: this.$store.state.common.baseUrl.mediaControl.mySource || false,
      mediaLoaded: false,
      hasStopSelectR: false,
      reConnectTimer: null, // 用于恢复已经start的voip
      firstLoadImg: true, // use it and loadtime to load img
      firstLoadTime: 0,
      thumImgUrl: '',
      gridRequestStatus: this.gridTokenStatus, // 当前grid的请求状态
      locationInfoCu: {}, // 当前geo信息
      locationHref: '', // 有三种值 : 1. "" 代表websocket没有返回值, 2."NA" 代表获取到值了 但是不合法 3.href值 可以跳转的值
      confirmObj: {
        hasSlugPerimission: false,
        checkCreateEvent: false,
        showFlag: false,
        name: '',
        peerId: '',
        sourceType: '',
        checkBehavior: false
      }
    }
  },
  created () {
    if (this.sourceListItemOps.status == 2) {
      const rid = this.sourceListItemOps.livePeerId ? this.sourceListItemOps.livePeerId.split(';')[0] : ''
      this.deviceList = this.currentSourceId + ',' + rid
    }
    let voip = sessionStorage.getItem('voip')
    if (voip) {
      voip = JSON.parse(voip)
      if (voip[this.currentSourceId]) {
        if (voip[this.currentSourceId].status == 2 && voip[this.currentSourceId]) {
          let array = []
          array.push('0x' + this.currentSourceId.toUpperCase())
          if (this.reConnectTimer) clearTimeout(this.reConnectTimer)
          this.reConnectTimer = setTimeout(() => {
            const _voipObj = { ...voip, type: 'audio' }
            this.Webrtc.startCall(array, _voipObj, (result) => {
              if (result.type == 'disconnectpeer' && result.msg.peerId) {
                // 关闭Voip
                this.setLocationStopVoip(voip, result.msg.peerId)
                return
              }
              if (this.$refs.audioObject) {
                this.$refs.audioObject.srcObject = result.msg.mediaStream
              }
            })
          }, 2000)
        }
      }
    }
    if (this.ifOpenMediaService) {
      this.isCompleteLoad()
    }
  },
  methods: {
    showSlugBox () {
      this.confirmObj.checkCreateEvent = false
      this.confirmObj.showFlag = true
      this.confirmObj.hasSlugPerimission = this.sourceListItemOps.hasSlugPerimission
      this.confirmObj.peerId = this.sourceListItemOps.peerId.toLowerCase()
      this.confirmObj.name = this.sourceListItemOps.name
      if (this.sourceListItemOps.platform >= 10 && this.sourceListItemOps.platform <= 14) {
        this.confirmObj.sourceType = 'Anywhere'
      } else {
        this.confirmObj.sourceType = 'Pack'
      }
      this.confirmObj.checkBehavior = false
    },
    setIcon (flag) {
      return SETSOURCEICON(this.allSourceDataUpdate[this.sourceListItemOps.peerId], flag)
    },
    // 打开bookmark box
    operationBookmarkBox (val) {
      this.isShow.bookmark = val
    },
    loadThumImg () {
      if (this.firstLoadImg) {
        this.firstLoadImg = false
        this.firstLoadTime = new Date().getTime()
        this.thumImgUrl = SHOWDEVICEIMG(this.allSourceDataUpdate[this.sourceListItemOps.peerId])
      } else {
        if (new Date().getTime() - this.firstLoadTime < 5000) return
        this.firstLoadTime = new Date().getTime()
        const item = this.allSourceDataUpdate[this.sourceListItemOps.peerId]
        if (item.status == 2 && item.type != 'X') {
          if (this.imgUrlTime.current[item.livePeerId] == this.imgUrlTime.last[item.livePeerId]) return
        } else {
          if ((item.type == 'Ext' || item.type == 'YouTube' || item.type == 'SRT' || item.type == 'SMPTE2022' || item.type == 'FILE') && item.status == 1) {
            item.thumImgUrl = SHOWDEVICEIMG(item)
          } else {
            if (this.imgUrlTime.current[this.sourceListItemOps.peerId] == this.imgUrlTime.last[this.sourceListItemOps.peerId] && item.status == 1) return
          }
        }
        this.thumImgUrl = SHOWDEVICEIMG(item)
      }
    },
    stopRState () {
      this.hasStopSelectR = true
    },
    handleList (val) {
      this.currentSocketData = handleSource(this.sourceId, this.receiverId, this.selectRIsLive, val)
    },
    isCompleteLoad () {
      if (typeof mediaObj != 'undefined') {
        this.mediaLoaded = true
      } else {
        setTimeout(() => {
          this.isCompleteLoad()
        }, 100)
      }
    },
    // 控制分享box的显示隐藏
    toggleShareBox () {
      if (this.currentSocketData && this.currentSocketData.status != '2') {
        return
      }
      this.shareFlag = !this.shareFlag
      if (this.shareFlag) {
        mySourceAnalyze('mySourceOpenShare', this.$route.path)
      } else {
        mySourceAnalyze('mySourceCloseShare', this.$route.path)
      }
      if (!this.shareFlag && typeof ShareBox === 'function' && typeof (ShareBox.close) === 'function') {
        ShareBox.close(this.sourceId)
      }
    },
    ...mapMutations('home', [
      'INIT_LOGIN'
    ]),
    // 点击显示设备详情的btn
    changeShowDeviceDetail (val) {
      this.isShow.deviceDetail = val
    },
    changeHadSelectDevice (val) {
      /*
      * 获取是否重新选择了R
      * 此处接收两处地方的数据,一个是VideoButton是否操作完成的信息(start,stop)以便SelectSourceR组件来更新input里面的显示值
      * 一个是接收SelectSourceR里面的数据 以便VideoButton组件知道是否重新选择了R
      *
      */
      this.isSelectNewR = val
    },
    showVideo (sourceId) {
      if (!sourceId) return false // sourceId为当前点击的source id 直接传进来这个id不会出错  NCC-283
      if (this.allSourceData[sourceId] == undefined) {
        // console.error("Don't get Info1");
        return false
      } else {
        if (this.allSourceData[sourceId] && this.allSourceData[sourceId].status && this.allSourceData[sourceId].status == 0) return false
      }
      Bus.$emit('clearVideo')

      const data = {
        key: this.listKey,
        currentSourceData: this.allSourceData[sourceId],
        sourceId: sourceId, // 用以做前后对比 以便判断是否显示播放组件
        rid: this.rItemId
      }
      this.$emit('showVideo', data) // 将播放的R id或者默认的id 和其他信息发送给播放组件
      Bus.$emit('restoreDefaultConfig')
    },
    async getFirstR () {
      const param = {
        sourceId: this.currentSourceId,
        pageNum: 1,
        pageSize: 15,
        condition: this.rDisplayInfo,
        deviceType: this.sourceListItemOps.type.toUpperCase()
      }

      return new Promise((resolve, reject) => { // 使用 Promise来解决同步异步问题
        this.axios.get('/ccp/tvucc-user/userDevice/pageSourceAvailableRSort', { params: param }).then(res => {
          const data = res.data
          if (data.errorCode == '0x0') {
            const list = data.result.list
            if (list && list.length > 0) {
              resolve(list[0].peerId) // 最后的返回值要使用resolve来传送 相当于一个return吧
            }
          }
        }).catch(() => {})
      })
    },
    getSelectInfo (data) { // 获取SelectSourceR.vue组件的参数
      this.deviceList = data.deviceList// 监听SelectSourceR.vue组件传递过来的参数
      const currentSelectRid = data.deviceList.split(',')[1]
      const gridLivePeerId = this.allSourceData[this.sourceListItemOps.peerId] ? this.allSourceData[this.sourceListItemOps.peerId].gridLivePeerId : null
      if (gridLivePeerId != null) {
        if (gridLivePeerId.includes(currentSelectRid)) {
          this.deviceDetailid = currentSelectRid
        }
      }
      this.hasStopSelectR = false
      this.receiverId = data.deviceList.split(',')[1]
      this.selectRIsLive = this.currentSocketData.gridLivePeerId != null && this.currentSocketData.gridLivePeerId.includes(this.receiverId)
      this.$emit('getSelectInfoContent', data) // 将选择的当前R向上传递到sourceContent中,
    },
    setLocationStopVoip (voip, peerId) {
      if (!voip || !peerId) return
      let operateP = peerId.toUpperCase()
      if (operateP.substring(0, 2) == '0X') operateP = operateP.slice(2)
      const lowserPeerId = operateP.toLowerCase()
      if (voip[lowserPeerId]?.peerId && operateP == (voip[lowserPeerId].peerId).toUpperCase()) {
        voip[lowserPeerId].status = 0
        sessionStorage.setItem('voip', JSON.stringify(voip))
      }
    },
    startVoip (item) {
      if (this.reConnectTimer) clearTimeout(this.reConnectTimer)
      this.lastClick = this.currentClick
      this.currentClick = new Date().getTime()
      if (this.currentClick - this.lastClick <= 2000) { // 如果voip点击频率时间快于2秒 return
        return false
      }
      const feature = this.$store.state.common.baseUserInfo.userFeatures
      let can = true // 是否有Voip的权限
      feature.map((item, index) => {
        const featureName = item.feature
        if (featureName == 'voip') {
          if (item.value != 1) {
            can = false
          }
        }
      })
      if (!can) { // 无权限
        this.$message({
          type: 'warning',
          message: this.$t('lang.notHaveVoipauthor')
        })
        return false
      }

      const isHttps = window.location.protocol.indexOf('https')
      if (isHttps >= 0) { // isHttps >= 0
        var voip = sessionStorage.getItem('voip')
        if (voip && JSON.parse(voip)[item.peerId]) {
          const status = JSON.parse(voip)[item.peerId].status
          if (status == '2' || status == '1') {
            if (status == '2') {
              mySourceAnalyze('mySourceVoipStop', this.$route.path) // 谷歌统计
              this.audioStream = false
            }
            this.Webrtc.stopCall('0x' + item.peerId.toUpperCase())
            voip = JSON.parse(voip)
            this.setLocationStopVoip(voip, item.peerId)
            return
          }
        }
        let array = []
        const peerId = item.peerId
        const ursId = '0x' + peerId.toUpperCase()
        mySourceAnalyze('mySourceVoipStart', this.$route.path) // 谷歌统计
        array.push(ursId)
        if (voip) {
          voip = JSON.parse(voip)
          voip[peerId] = {
            status: 1,
            peerId: peerId
          }
          sessionStorage.setItem('voip', JSON.stringify(voip))
        } else {
          const obj = {
            status: 1,
            peerId: peerId
          }
          let voipObj = {}
          voipObj[peerId] = obj
          sessionStorage.setItem('voip', JSON.stringify(voipObj))
        }
        const _self = this
        const _voipObj = { ...voip, type: 'audio' }
        this.Webrtc.startCall(array, _voipObj, (result) => {
          let voip = sessionStorage.getItem('voip')

          if (result.type === 'success') {
            if (voip) {
              voip = JSON.parse(voip)
              if (voip[peerId]) {
                voip[peerId].status = 2
                sessionStorage.setItem('voip', JSON.stringify(voip))
              }
            }
            if (_self.$refs.audioObject) {
              _self.$refs.audioObject.srcObject = result.msg.mediaStream
            }
          } else if (result.type === 'disconnected' || result.type === 'failed' || result.type === 'closeVoip' || result.type === 'disconnectpeer') {
            if (voip && result.msg.peerId) {
              voip = JSON.parse(voip)
              this.setLocationStopVoip(voip, result.msg.peerId)
            }
          } else if (result.type === 'noHaveMediaDevice') {
            _self.$message({
              type: 'warning',
              message: this.$t('lang.getLocalMediaFail')
            })
            return false
          }
        })
      } else {
        this.$confirm(this.$t('lang.confirmHttps'), this.$t('lang.modelTips'), {
          confirmButtonText: this.$t('lang.confirm'),
          cancelButtonText: this.$t('lang.cancel'),
          type: 'warning',
          center: true,
          iconClass: 'myIcon',
          callback: action => {
            if (`${action}` == 'confirm') {
              // 这地方不对 需要修改 可能会有https端口不为默认的443的情况
              window.location.href = window.location.href.replace('http', 'https')
            }
          }
        })
      }
    },
    search (clearArr) {
      const param = {
        sourceId: this.currentSourceId,
        pageNum: this.page,
        pageSize: this.size,
        condition: this.rDisplayInfo,
        deviceType: this.sourceListItemOps.type.toUpperCase()
      }
      this.axios.get('/ccp/tvucc-user/userDevice/pageSourceAvailableRSort', { params: param }).then(res => {
        const data = res.data
        if (data.errorCode == '0x0') {
          const list = data.result.list
          this.hasNextPage = data.result.hasNextPage
          if (list && list.length > 0) {
            this.everyRList = list
            if (!clearArr) {
              this.queryInfo()
            } else {
              this.rlistArr = list
            }
          } else {
            this.page = 1
          }
        } else {
          this.page = 1
        }
      }).catch(() => {})
    },
    searchClick () {
      this.rlistArr = []
      this.page = 1
      this.rlistFlag = !this.rlistFlag
      this.search(true)
    },
    searchNotHide () {
      this.rlistFlag = true
      this.rlistArr = []
      this.page = 1
      this.search(true)
    },
    onScroll () {
      if (this.hasNextPage) {
        this.page += 1
        this.search(false)
      }
    },
    queryInfo () {
      this.rlistArr = this.rlistArr.concat(this.everyRList)
    },
    select (val) {
      this.data = 0
      this.page = 1
      this.noMore = false
      this.$el.querySelector('.scrollbar').scrollTop = 0
      this.queryInfo()
    },
    gotoMap (url) { // 跳转到map
      mySourceAnalyze('mySourceGotoMap', this.$route.path) // 谷歌统计
      window.open(url)
    }
  },
  watch: {
    allSourceData (val, oldval) { // 监听所有的数据变化
      if (!this.isSelectNewR) {
        if (val[this.currentSourceId]) {
          if (val[this.currentSourceId].status == 2) {
            const currentSelectRid = this.deviceList.split(',')[1]
            if (val[this.currentSourceId].gridLivePeerId != null && currentSelectRid && val[this.currentSourceId].gridLivePeerId.includes(currentSelectRid)) {
              this.deviceDetailid = currentSelectRid
              this.rItemId = this.deviceDetailid
              this.receiverId = this.rItemId
            } else {
              this.deviceDetailid = val[this.currentSourceId].livePeerId
              this.rItemId = this.deviceDetailid
              this.receiverId = this.rItemId
              const getSelectInfo = {
                rid: this.rItemId,
                deviceList: this.currentSourceId + ',' + this.rItemId
              }
              this.$emit('getSelectInfoContent', getSelectInfo)// 如果livePeerId变化了 将livepeerId 传递过去 供video使用
            }
            if (val[this.currentSourceId].status == 2) { // 如果正在live 将最新的R Id发送给Take组件
              if (this.deviceList) {
                const currentSelectRidAuto = this.deviceList.split(',')[1]
                if (val[this.currentSourceId].gridLivePeerId != null && currentSelectRidAuto && val[this.currentSourceId].gridLivePeerId.includes(currentSelectRidAuto)) {
                } else {
                  this.deviceList = this.currentSourceId + ',' + this.rItemId
                }
              } else {
                this.deviceList = this.currentSourceId + ',' + this.rItemId
              }
            }
          }
          if (val[this.currentSourceId].livePeerName) {
            if (val[this.currentSourceId].livePeerName.indexOf(this.rDisplayInfo) < 0 || this.rDisplayInfo == '') { // 说明最新的livepeerId里面没有以前的 重新赋值
              this.rDisplayInfo = val[this.currentSourceId].livePeerName.split(';')[0]
            }
          }
        }
      }
      Object.keys(val).map(v => {
        if (val[this.sourceListItemOps.peerId]) {
          this.allSourceDataUpdate = val
        }
      })
    },
    // 监听当前Source状态，控制media的显示隐藏
    currentSocketData (val, oldval) {
      if (this.ifOpenMediaService) {
        if (this.hasStopSelectR) {
          this.receiverId = val.livePeerId
        }
        this.selectRIsLive = val.gridLivePeerId != null && val.gridLivePeerId.includes(this.receiverId)
        this.handleList(val)
        this.loadThumImg()
      }
    },
    gridTokenStatus (val) {
      // 监听grid request status的状态变化
      this.gridRequestStatus = Object.assign({}, val)
    },
    locationInfo (val) {
      if (val.length > 0) {
        val.map((item) => {
          if (item.peerId.toLowerCase() == this.sourceListItemOps.peerId.toLowerCase() || (this.sourceListItemOps.type.toLowerCase() == 'x' && item.peerId.toLowerCase() == this.sourceListItemOps.peerId.toLowerCase().slice(0, 16))) {
            if (typeof item.address != 'string') {
              let address = ''
              if (item.address) {
                item.address.map((itm, inx) => {
                  if (inx != item.address.length - 1) {
                    address += (itm.long_name + ' , ')
                  } else {
                    address += itm.long_name
                  }
                })
              }
              item.address = address
            }
            this.locationInfoCu = Object.assign({}, item)
            if (item.location && item.location.trim() != 'null,null') {
              const lat = item.location.split(',')[0]
              const lng = item.location.split(',')[1]
              this.locationnoA = true// location可以用
              this.locationHref = encodeURI(`${location.protocol}//${localStorage.getItem('newCCUrl')}/mapPage.html#?lat=${lat}&lng=${lng}&name=${this.sourceListItemOps.name}`) // 线上使用
            } else {
              this.locationHref = 'NA'// location不可以用
            }
          }
        })
      }
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRequest: state => state.websocket.pageRequest
    }),
    ...mapState('common', {
      Webrtc: state => state.webrtc
    }),
    showSizeClass () {
      return this.$store.state.common.showDeviceSize
    },
    openOnGoingEvent () {
      return this.$store.state.common.baseUrl.openOnGoingEvent
    }
  },
  filters: {
    // Thomas add
    showVoipImg (item) {
      let status = 0
      const voip = sessionStorage.getItem('voip')
      if (voip && JSON.parse(voip)[item.peerId]) {
        status = JSON.parse(voip)[item.peerId].status
      }
      return VoipImage[status]
    }
  },
  beforeDestroy () {
    let voip = sessionStorage.getItem('voip')
    if (voip) {
      voip = JSON.parse(voip)
      if (voip[this.currentSourceId]) {
        if (voip[this.currentSourceId].status == 2) {
          this.Webrtc.stopCall('0x' + voip[this.currentSourceId].peerId.toUpperCase())
        }
      }
    }
    ;
  }
}
</script>

<style lang="less" scoped>
.cursorImg {
  cursor: no-drop;
}
.locationSet {
  position: absolute;
  cursor: pointer;
  height: 25px;
  /*width: 178px;*/
  width: 100%;
  font-size: 0.1rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  line-height: 25px;
  z-index: 1;
  color: #fff;
  right: 0;
  top: 0;

  a {
    display: inline-block;
    width: 52%;
    height: 100%;
    margin-left: 48%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    /*background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, rgba(0, 0, 0, 0) 100%);*/
  }

  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    padding: 0 4px;
  }
}

::v-deep .el-input .el-input__inner {
  border: none;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background: #424242 !important;
}

::v-deep .el-input.is-disabled:hover {
  border: none;
}

::v-deep .el-button--info.is-plain.is-disabled,
::v-deep .el-button--info.is-plain.is-disabled:hover,
::v-deep .el-button--info.is-plain.is-disabled:focus,
::v-deep .el-button--info.is-plain.is-disabled:active {
  background: #676767;
}

::v-deep .el-image {
  width: 100%;
  height: 100%;
}
.sourceItem {
  width: 364px;
  height: 292px;
  background: #08130a;
  position: relative;
  /*margin-right: 15px;*/
  margin-right: 15px;
  margin-bottom: 10px;
  float: left;
  //border: 4px solid white; ///
  //border-radius: 8px; ///

  .pairedImg {
    width: 16px;
    height: 16px;
    margin-top: 17.5px;
    margin-right: 5px;
    margin-left: 10px;
  }

  .left {
    float: left;
  }

  .thumbnail-view-img {
    height: 205px;
    width: 100%;
    cursor: pointer;
    border: none;
    overflow: hidden;
    //border-radius: 5px; ///
    img {
      width: 100% !important;
      max-height: 100% !important;
      border: none;
    }
  }

  .extBg {
    height: 205px;
    width: 100%;
    // background: url("../../assets/img/ext.jpg") no-repeat center;
    background: url('../../assets/img/External.png') no-repeat center;
    background-size: 100%;
  }

  .youtubeBg {
    height: 205px;
    width: 100%;
    // background: url("../../assets/img/youtube.jpg") no-repeat center;
    background: url('../../assets/img/YouTube_2.png') no-repeat center;

    background-size: 100%;
  }

  .packBg {
    height: 205px;
    width: 100%;
    background: url('../../assets/img/logo_thumbnail.png') no-repeat center;
    background-size: 100%;
  }

  .SMPTE2022Bg {
    height: 205px;
    width: 100%;
    background: url('../../assets/img/SMPTE.png') no-repeat center;
    background-size: 100%;
  }

  .t_thumbnail_view_item_icon {
    position: absolute;
    top: 0.16rem;
    left: 16px;
    width: 60px;
    height: 28px;
    border: none;
    background: #1ec345;
    border-radius: 4px;
    line-height: 28px;
    text-align: center;
  }

  .sourceItemName {
    margin-left: 2%;
    height: 47px;
    text-align: left;
    padding: 0 10px;
    line-height: 47px;
    font-size: 12px;
    width: 94%;
    .deviceIcon {
      margin: 0 3px 0 0;
      font-size: 15px;
      cursor: inherit;
    }
    i.bookmark_icon {
      margin-right: 5px;
      color: #dbdbdb;
    }
    .el-icon-collection-tag {
      margin-top: 16px;
    }

    .voipBox {
      width: 24px;
      // height: 24px;
      margin-right: 8px;
    }
  }

  .sourceSearch {
    width: calc(100% - 100px);
    height: 34px;
    margin-left: 8px;
    z-index: 1;
    /*position: relative;*/

    ::v-deep input {
      border: none;
    }
    .scrollbar {
      position: absolute;
      top: 34px;
      left: 0;
      width: 100%;
      height: 212px;
      overflow-y: auto;
      background: #444;
      margin-bottom: 30px;
    }

    .scrollbar li {
      display: block;
      width: 100%;
      color: #fff;
      height: 26px;
      line-height: 26px;
      cursor: pointer;
      position: relative;
      text-indent: 40px;
      text-align: left;
    }

    .scrollbar li:hover {
      background: #455f45;
    }

    .scrollbar::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 7px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background-color: rgba(144, 147, 153, 0.2);
      opacity: 0.7;
    }

    .scrollbar::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 10px;
      background: none;
    }

    ::v-deep .inputColorLive input {
      color: #33ab4f;
    }

    ::v-deep .inputColorOffline input {
      color: #676767 !important;
    }

    ::v-deep .inputColorOffline input::placeholder {
      color: #676767 !important;
    }
  }

  .switchSourcePeer {
    height: 34px;
    width: 100%;

    .sourveSearch {
      float: left;
    }
  }
}

.sourceInner {
  height: 47px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 55%;
}
.descIcon {
  cursor: pointer;
  margin-right: 8px;
  line-height: 47px;
  padding-top: 3px;
  box-sizing: border-box;
  .el-icon-date {
    font-size: 20px;
    &.notPerimission {
      color: #666;
    }
    &.onGoingIcon {
      color: #33ab4f;
    }
  }
}

.sourceGreenBg {
  background: #39aa50 !important;
}

.sourceRedBg {
  background: #db1a14 !important;
}

.sourceGrayBg {
  background: #565656 !important;
}

.sourceTakeBtn {
  height: 30px;
  line-height: 8px !important;
  border: none !important;
  margin-left: 28px !important;
  background: #565656;
  color: #fff;
}

.liveBg {
  background: rgba(255, 51, 0, 1) !important;
  opacity: 0.76;
}

.offlineBg {
  background: rgba(153, 153, 153, 1) !important;
  opacity: 0.76;
}

.onlineBg {
  background: rgba(30, 195, 69, 1) !important;
  opacity: 0.76;
}

.red {
  color: #f00;
}

.gray {
  color: #999;
}
</style>
